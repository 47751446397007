import React from 'react';
import './Services.css'; // Import your CSS file if needed
import { Container, Row, Col } from 'react-bootstrap';
import GetTouch from './GetTouch';

const Services = () => {
  return (
    <>
      <div className="video-background container-fluid">
        <video autoPlay loop muted>
          <source src="video/Blue and White Illustrative Modern Science and Technology Education Video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="overlay">
          <Container>
            <Row className="justify-content-center align-items-center">
              <Col md={10}>
                <h1 className='head45 text-center'>Our Expertise</h1>
                <p className='head46 text-center'>"JBJM AI and Cloud specializes in providing Azure DevOps services, delivering tailored solutions to optimize digital application development and deployment processes for our clients."</p>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <div className="row service container-fluid mt-5">
        <div className="heading-container">
          <h1 className="heading5">Our Services</h1>
        </div>

        {/* Service Cards */}
        <div className="column demo90 mt-5">
          <div className="servicecard">
            <div className="icon-wrapper">
              <i className="fa fa-cogs"></i>
            </div>
            <h3 className="head1">Continuous Integration/Continuous Deployment (CI/CD)</h3>
            <p className="paragraph">
              Setting up and maintaining robust CI/CD pipelines on Azure to streamline the software development lifecycle.
            </p>
          </div>
        </div>

        <div class="column demo90 mt-5">
          <div class="servicecard">
            <div class="icon-wrapper">
              <i class="fa fa-server"></i>
            </div>
            <h3 class="head1">Infrastructure as Code (IaC)</h3>
            <p class="paragraph">
              Implementing infrastructure automation using tools such as Terraform or Ansible to ensure consistency and scalability on Azure Cloud.
            </p>
          </div>
        </div>

        <div class="column demo90 mt-5">
          <div class="servicecard">
            <div class="icon-wrapper">
              <i class="fa fa-cube"></i>
            </div>
            <h3 class="head1">Containerization</h3>
            <p class="paragraph">
              Utilizing containerization technologies like Docker to enhance application portability and deployment efficiency.
            </p>
          </div>
        </div>


        <div className="column demo90 mt-5">
          <div class="servicecard">
            <div class="icon-wrapper">
              <i class="fa fa-chart-line"></i>
            </div>
            <h3 class="head1">Monitoring & Logging</h3>
            <p class="paragraph">
              Implementing effective monitoring and logging solutions to proactively identify and address issues.
            </p>
          </div>
        </div>


        <div className="column demo90 mt-5">
          <div class="servicecard">
            <div class="icon-wrapper">
              <i class="fa fa-users"></i>
            </div>
            <h3 class="head1">Collaboration & Communication</h3>
            <p class="paragraph">
              Facilitating collaboration between development and operations teams through tools like Slack, Microsoft Teams, or other communication platforms.
            </p>
          </div>
        </div>


        <div className="column demo90 mt-5">
          <div class="servicecard">
            <div class="icon-wrapper">
              <i class="fa fa-cloud-upload"></i>
            </div>
            <h3 class="head1">Migration of Application to Cloud</h3>
            <p class="paragraph">
              We ensure a smooth and secure migration process and unlock the full potential of cloud infrastructure for improved performance and scalability applications.
            </p>
          </div>
        </div>



        <div className="column demo90 mt-5">
          <div className="servicecard">
            <div className="icon-wrapper">
              <i className="fa fa-mobile"></i> {/* Example icon */}
            </div>
            <h3 className="head1">Mobile App Development</h3>
            <p className="paragraph">
              Developing high-quality mobile applications for iOS and Android platforms tailored to your business needs.
            </p>
          </div>
        </div>


        <div className="column demo90 mt-5">
          <div className="servicecard">
            <div className="icon-wrapper">
              <i className="fa fa-desktop"></i> {/* Example icon for web design */}
            </div>
            <h3 className="head1">Web Design</h3>
            <p className="paragraph">
              Crafting visually appealing and user-friendly websites tailored to meet your business objectives and enhance your online presence.
            </p>
          </div>
        </div>
        {/* End of Web Design Service */}

        {/* Additional Service: Ecommerce Website Development */}
        <div className="column demo90 mt-5">
          <div className="servicecard">
            <div className="icon-wrapper">
              <i className="fa fa-shopping-cart"></i> {/* Example icon for ecommerce */}
            </div>
            <h3 className="head1">Ecommerce Website Development</h3>
            <p className="paragraph">
              Building secure and scalable ecommerce platforms that provide seamless shopping experiences for your customers.
            </p>
          </div>
        </div>
        {/* End of Ecommerce Website Development Service */}

        {/* Additional Service: Internet Marketing Services */}
        <div className="column demo90 mt-5">
          <div className="servicecard">
            <div className="icon-wrapper">
              <i className="fa fa-globe"></i> {/* Example icon for internet marketing */}
            </div>
            <h3 className="head1">Internet Marketing Services</h3>
            <p className="paragraph">
              Implementing effective digital marketing strategies to increase online visibility, drive traffic, and generate leads for your business.
            </p>
          </div>
        </div>
        {/* End of Internet Marketing Services */}

        {/* Additional Service: Custom Software Development */}
        <div className="column demo90 mt-5">
          <div className="servicecard">
            <div className="icon-wrapper">
              <i className="fa fa-code"></i> {/* Example icon for custom software development */}
            </div>
            <h3 className="head1">Custom Software Development</h3>
            <p className="paragraph">
              Designing and developing tailored software solutions that address your unique business needs and streamline operations.
            </p>
          </div>
        </div>
        {/* End of Custom Software Development Service */}

        {/* Additional Service: Shopify Store Development */}
        <div className="column demo90 mt-5">
          <div className="servicecard">
            <div className="icon-wrapper">
              <i className="fa fa-shopping-bag"></i> {/* Example icon for Shopify store development */}
            </div>
            <h3 className="head1">Shopify Store Development</h3>
            <p className="paragraph">
              Creating fully customized Shopify stores that reflect your brand identity and drive sales.
            </p>
          </div>
        </div>
        {/* End of Shopify Store Development Service */}

        {/* Additional Service: Website Hosting / Website Migration */}
        <div className="column demo90 mt-5">
          <div className="servicecard">
            <div className="icon-wrapper">
              <i className="fa fa-server"></i> {/* Example icon for website hosting / migration */}
            </div>
            <h3 className="head1">Website Hosting / Website Migration</h3>
            <p className="paragraph">
              Providing reliable website hosting solutions and seamless migration services to ensure smooth transitions to new environments.
            </p>
          </div>
        </div>

      </div>

      <br></br>
      <GetTouch></GetTouch>
      <br></br>

    </>



  );
};

export default Services;

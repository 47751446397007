import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './AboutSection.css';
import GetTouch from './GetTouch';

const AboutSection = () => {
  return (
    <>

<div className="header container-fluid">
      <div className="overlay"></div>
      <div className="header-text">
        <h1>About JBJM AI and Cloud</h1>
        <p className="header-text1">We are a digitally native company that helps organizations reinvent themselves and unleash their potential</p>
      </div>
    </div>
    <section className="about-section container">
      <div className="container">
        <div className="row">
          {/* Content Column */}
          <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
            <div className="inner-column">
              <div className="sec-title">
                <h2 className="title">About us</h2>
                <h2>Welcome to JBJM AI and Cloud <br />Artificial Intelligence & Cloud Computing Platform</h2>
              </div>
              <p className="text">Welcome to JBJM AI and Cloud Pvt Ltd, where innovation meets excellence in the realms of
                Artificial Intelligence (AI) and Cloud Computing. As a pioneering technology company, we are
                committed to transforming businesses through cutting-edge solutions that harness the power
                of AI and leverage the scalability of cloud platforms.<br />
                JBJM AI and Cloud Pvt Ltd was founded with a vision to revolutionize the digital landscape by
                seamlessly integrating AI and cloud technologies. Our team of skilled professionals is
                dedicated to delivering intelligent, scalable, and future-ready solutions that empower
                businesses to thrive in the era of digital transformation.
              </p>
              <div className="btn-box">
                {/* Use Link component to navigate to Contact component */}
                <Link to="/contact" className="theme-btn btn-style-one">Contact Us</Link>
              </div>
            </div>
          </div>

          {/* Image Column */}
          
          <div className="image-column col-lg-6 col-md-12 col-sm-12">
            <div className="inner-column">
              <figure className="image-1">
                <a href="#" className="lightbox-image" data-fancybox="images">
                  <img src="Image\ai1.jpg" alt="" />
                </a>
              </figure>
              <figure className="image-2">
                <a href="#" className="lightbox-image" data-fancybox="images">
                  <img src="Image\ai90.jpg" alt="" />
                </a>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>

      
       <GetTouch></GetTouch>
       <br></br>

    </>
  );
};

export default AboutSection;
